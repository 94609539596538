import { FC, useEffect } from 'react'

import { useDispatch } from 'react-redux'

import { useInterval } from 'hooks/useInterval'
import { useReducersInsert } from 'hooks/useReducersInsert'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import { systemViewReducer } from 'reducers/system/systemViewReducer'

export const RepeatableAction: FC<{
  timeout: number
  action: Function
  visibleOnly?: boolean
  runAtStartup?: boolean
}> = ({
  timeout = 10000,
  action,
  visibleOnly = false,
  runAtStartup = false,
}) => {
  useReducersInsert({ systemView: systemViewReducer })

  const dispatch = useDispatch()

  const { pageVisible } = useShallowEqualSelector(
    ({ systemView: { pageVisible } }) => ({
      pageVisible,
    })
  )

  const handleInterval = () => {
    if (!visibleOnly || pageVisible) {
      dispatch(action())
    }
  }

  useInterval(handleInterval, timeout)

  useEffect(() => {
    if (runAtStartup) {
      dispatch(action())
    }
  }, [action, dispatch, runAtStartup])

  return null
}
