import React, { FC, useMemo } from 'react'

import styled, { CSSProperties } from 'styled-components'

import { Colors } from 'common-constants/colors'
import { FontSize } from 'common-constants/sizes'
import { PlainLink } from 'components/presentational/link'
import { isRussia } from 'functions/country/country.functions'
import { findCorporateSiteLocale } from 'functions/index'
import { useLocale } from 'hooks/useLocale'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'

export const RecommendationTechnologiesLink: FC<{
  style?: CSSProperties
}> = ({ style }) => {
  const locale = useLocale()
  const { currentLocation } = useShallowEqualSelector(
    ({ location: { currentLocation } }) => ({
      currentLocation,
    })
  )
  const corporateLocale = useMemo(() => findCorporateSiteLocale(locale), [
    locale,
  ])

  if (!isRussia(currentLocation?.country!) || currentLocation === null) {
    return null
  }

  return (
    <Text style={style}>
      На информационном ресурсе применяются рекомендательные{' '}
      <PlainLink
        href={`https://corp.mamba.ru/${corporateLocale}/recommendation`}
      >
        технологии.
      </PlainLink>
    </Text>
  )
}

const Text = styled.div`
  color: ${Colors.specialNotFoundGray};
  text-align: center;
  font-size: ${FontSize.note}px;
  padding: 0 15px;
`
