import { Reducer } from 'react'

import { AsyncAction } from 'actions/actions.types'
import {
  SeriousSearchAction,
  SERIOUS_SEARCH,
} from 'actions/landing/fetchSeriousSearchAction'
import {
  fetchSnippetContentAction,
  FETCH_SNIPPET_CONTENT,
} from 'actions/landing/fetchSnippetContentAction'
import {
  CALCULATE_BANNERS_URLS,
  CLEAR_LANDING_DATA,
  LANDING_SEARCH,
  LandingActionTypes,
} from 'actions/landing/landingAction'
import {
  FETCH_ADS_LINKS,
  FETCH_SEO_SNIPPET,
  FETCH_TOP_LOCATION_LINKS,
  LGBT_BLOCKED,
  RESOLVE_SEO_LOCATION,
  SeoActionTypes,
} from 'actions/landing/seoAction'
import {
  FETCH_CURRENT_LOCATION,
  fetchCurrentLocationPlainAction,
} from 'actions/location/fetchCurrentLocationAction'
import { definitions as seo } from 'api/generated/seo'
import { SnippetType } from 'api/landing/fetchSnippetContentApi'
import { createSeoLandingLocationType } from 'components/page/Landing/Landing.functions'
import { LandingUser } from 'components/page/Landing/LandingView/LandingView.types'
import { isRussia } from 'functions/country/country.functions'
import { defaultPromiseReducer } from 'reducers/defaultPromiseReducer'
import { calculateBannerPhotos } from 'reducers/landing/landing.functions'

export enum LandingType {
  city = 'city',
  region = 'region',
  country = 'country',
}

export interface GeoLandingState {
  seoSnippetTitle: string | null
  seoSnippetText: string | null
  seoSnippetSecondary: string | null
  adsLinks: seo['SeoLink'][]
  seoLocation: seo['SeoLocationDto'] | null
  topLocationsLoaded: boolean
  topLocationLinks: seo['SeoLink'][]
  isLoading: 0
  lgbtBlocked: boolean

  usersLoading: boolean
  userLoadingFailed: boolean

  bannerPhotoUrl: string
  anotherBannerPhotoUrl: string
  bannerPhotoWebpUrl: string
  anotherBannerPhotoWebpUrl: string
  landingUsers: LandingUser[]
  seoLocationType: LandingType | undefined
  seoLocationResolved: undefined | boolean
}

const searchBefore = (): Partial<GeoLandingState> => ({
  usersLoading: true,
  userLoadingFailed: false,
})

const searchAfter = (): Partial<GeoLandingState> => ({
  usersLoading: false,
  userLoadingFailed: true,
})

export const landingReducer: Reducer<
  GeoLandingState,
  | LandingActionTypes
  | SeoActionTypes
  | ReturnType<typeof fetchCurrentLocationPlainAction>
  | ReturnType<typeof fetchSnippetContentAction>
  | SeriousSearchAction
> = (
  state = {
    seoSnippetText: null,
    seoSnippetTitle: null,
    seoSnippetSecondary: null,
    adsLinks: [],
    seoLocation: null,
    topLocationsLoaded: false,
    topLocationLinks: [],
    isLoading: 0,
    lgbtBlocked: true,
    bannerPhotoUrl: '',
    anotherBannerPhotoUrl: '',
    bannerPhotoWebpUrl: '',
    anotherBannerPhotoWebpUrl: '',
    landingUsers: [],
    seoLocationType: undefined,
    seoLocationResolved: undefined,
    usersLoading: true,
    userLoadingFailed: false,
  },
  action
) => {
  switch (action.type) {
    case FETCH_SEO_SNIPPET:
      return defaultPromiseReducer(state, action, undefined, (result) => {
        if (result) {
          return {
            seoSnippetTitle: result.header,
            seoSnippetText: result.snippet,
          }
        }

        return state
      })

    case FETCH_SNIPPET_CONTENT:
      return defaultPromiseReducer(state, action, undefined, (result) => {
        if (result) {
          return {
            seoSnippetSecondary: result.find(
              ({ type }) => type === SnippetType.secondary
            )?.content,
          }
        }

        return state
      })

    case FETCH_ADS_LINKS:
      return defaultPromiseReducer(
        state,
        action,
        () => ({
          ...state,
          adsLinksWereLoaded: false,
        }),
        () => ({
          ...state,
          adsLinks: (action.result && action.result.links) || [],
          adsLinksWereLoaded: true,
        }),
        () => ({
          ...state,
          adsLinks: [],
          adsLinksWereLoaded: false,
        })
      )

    case FETCH_TOP_LOCATION_LINKS:
      return defaultPromiseReducer(
        state,
        action,
        () => ({
          topLocationsLoaded: false,
        }),
        () => ({
          topLocationLinks: (action.result && action.result.links) || [],
          topLocationsLoaded: true,
        }),
        () => ({
          topLocationLinks: [],
          topLocationsLoaded: true,
        })
      )

    case RESOLVE_SEO_LOCATION:
      return defaultPromiseReducer(
        state,
        action,
        () => ({
          ...state,
          seoLocationResolved: false,
        }),
        (seoLocation) => {
          const { location } = seoLocation
          const { city, country, region } = location

          return {
            ...state,
            seoLocation,
            seoLocationType: createSeoLandingLocationType(
              country,
              region,
              city
            ),
            seoLocationResolved: true,
          }
        },
        () => ({ seoLocationResolved: true })
      )

    case SERIOUS_SEARCH:
    case LANDING_SEARCH:
      return defaultPromiseReducer(
        state,
        action as AsyncAction<seo['SearchResult']>,
        searchBefore,
        (): Partial<GeoLandingState> => {
          const items = action.result?.items || []

          return {
            landingUsers: items.map(
              ({ aid, userpic, name, selfAge, isOnline, anketaStatuses }) => ({
                aid,
                userpic: userpic.square,
                name,
                selfAge,
                isOnline,
                /** Ошибка документации */
                anketaStatuses: (anketaStatuses as unknown) as seo['ProfileStatuses'],
              })
            ),
            usersLoading: false,
            userLoadingFailed: false,
          }
        },
        searchAfter
      )

    case CLEAR_LANDING_DATA:
      return {
        ...state,
        seoLocation: null,
        topLocationsLoaded: false,
        topLocationLinks: [],
        landingUsers: [],
        usersLoading: false,
        seoSnippetSecondary: null,
      }

    case CALCULATE_BANNERS_URLS:
      return {
        ...state,
        ...calculateBannerPhotos(),
      }

    case LGBT_BLOCKED:
      return {
        ...state,
        lgbtBlocked: action.value,
      }

    case FETCH_CURRENT_LOCATION:
      return defaultPromiseReducer(state, action, undefined, (result) => ({
        lgbtBlocked: isRussia(result.country),
      }))

    default:
      return state
  }
}
