import React, {
  forwardRef,
  MouseEventHandler,
  ReactNode,
  TouchEventHandler,
  useRef,
} from 'react'

import styled, { css } from 'styled-components'

import { isLoveMailru } from 'common/constants'
import { WithDataName } from 'common/types'
import { media } from 'components/presentational'
import { zIndex } from 'components/presentational/zIndex'
import { leftClickButton } from 'functions/leftClickButton'

import { modalOpenAttribute } from './modalOpen'

interface ContentProps {
  fade: boolean
  /**
   * Дополнительные отступы модального окна от краёв окна (viewport).
   * Значение по умолчанию: `'20px'`.
   *
   * **Важно:** Удалить это свойство после завершения редизайна и убедиться,
   * что на платформе LoveMail.ru всё работает корректно и модальное окно ничего не перекрывает.
   */
  offset?: string
  onOverlayClick: MouseEventHandler<HTMLElement>
}

export const ModalLayoutContent = forwardRef<
  HTMLDivElement,
  ContentProps &
    WithDataName & {
      children: ReactNode
    }
>(({ children, fade, offset, onOverlayClick, ...rest }, containerRef) => {
  const handleClick: MouseEventHandler<HTMLElement> = (event) => {
    if (modalElementRef?.current) {
      const isOverlayClicked =
        event.target === modalElementRef.current && leftClickButton(event) // ignore everything but left clicks

      if (isOverlayClicked) {
        onOverlayClick(event)
      }
    }
  }

  const modalElementRef = useRef<HTMLDivElement>(null)
  const dataName = rest['data-name']

  return (
    <>
      <BackgroundShadow $fade={fade} />
      <Container
        ref={containerRef}
        {...(dataName && { ['data-name']: dataName })}
      >
        <ContainerWidthWithoutScroll
          ref={modalElementRef}
          $offset={offset}
          onMouseDown={handleClick}
          onTouchStart={
            (handleClick as unknown) as TouchEventHandler<HTMLElement>
          }
        >
          {children}
        </ContainerWidthWithoutScroll>
      </Container>
    </>
  )
})

const modalIndexCss = css`
  z-index: ${(props) =>
    isLoveMailru(props.theme.partnerId) ? '1004' : zIndex.modal};
`

export const positionFixedCss = css`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
`

const Container = styled.div<WithDataName>`
  ${positionFixedCss};
  ${modalIndexCss};
  width: 100vw;
  overflow: auto;

  * {
    box-sizing: content-box;
  }
`

const ContainerWidthWithoutScroll = styled.div<{ $offset?: string }>(
  ({ $offset }) => css`
    display: flex;
    width: 100%;
    min-height: 100%;
    align-items: flex-start;
    justify-content: center;
    padding: ${$offset ?? '20px'};
    box-sizing: border-box;

    ${media.tablet`
      padding: 0;
    `};

    ${media.phone`
      width: 100vw;
      align-items: stretch;
      justify-content: stretch;
    `}
  `
)

export const BackgroundShadow = styled.div<{
  $fade: boolean
}>`
  ${positionFixedCss};
  background-color: ${(props) =>
    props.$fade ? 'rgba(0, 0, 0, 0.65)' : 'transparent'};

  ${modalIndexCss}

  ${media.phone`
    [${modalOpenAttribute}=true] + & {
      background-color: transparent;
    }       
  `};
`
