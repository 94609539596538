import React, { FC, MouseEvent } from 'react'

import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

import { updateSystemCookiesBannersAction } from 'actions/system/systemAction'
import {
  setCookieBannerPopUpRulesPath,
  pathnameFormName,
} from 'common-constants/formAction'
import { CloseSvg } from 'components/presentational/svg/CloseSvg'
import { StyledVisuallyHiddenText } from 'components/presentational/VisuallyHidden'
import { isPureDevMode } from 'functions/isPureDevMode'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { useLayoutContext } from 'hooks/useLayoutContext'
import { useRedesign } from 'hooks/useRedesign'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'

import { COOKIE_BANNER_CLOSE_BUTTON_DATA_NAME } from './BannerPopUpRulesCookie.constants'
import { BannerPopUpRulesCookieView } from './BannerPopUpRulesCookieView'
import { setCookieValue } from '../../../client/functions/setCookie'
import { fontFamily, appBannerPopUpRulesCookie } from '../../constants'
import { confidentialityLayoutPath } from '../layout/MambaLayout/paths'
import { media } from '../presentational'
import { RouterLink } from '../presentational/link'

export const BannerPopUpRulesCookie: FC = () => {
  const dispatch = useDispatch()
  const {
    cookies,
    authorized,
    pathname,
    yandexTurboApp,
  } = useShallowEqualSelector(
    ({
      systemReducer: { cookies, yandexTurboApp },
      authorizationReducer: { authorized },
      router: {
        location: { pathname },
      },
    }) => ({
      cookies,
      authorized,
      pathname,
      yandexTurboApp,
    })
  )

  const { baseUrl } = useLayoutContext()
  const cookieViewed = cookies[appBannerPopUpRulesCookie]
  const redesign = useRedesign()

  if (isPureDevMode()) {
    return null
  }

  const handleClick = (event: MouseEvent) => {
    event.preventDefault()
    setCookieValue(appBannerPopUpRulesCookie, 'true', 3650, false)
    dispatch(updateSystemCookiesBannersAction())
  }

  if (cookieViewed || authorized || yandexTurboApp) {
    return null
  }

  const confidentialityLink = mergeAllUrls(baseUrl, confidentialityLayoutPath)

  if (redesign) {
    return (
      <BannerPopUpRulesCookieView
        pathname={pathname}
        confidentialityLink={confidentialityLink}
        onCloseButtonClick={handleClick}
      />
    )
  }

  return (
    <Wrapper>
      <WrapperInner>
        <form
          id="closeCookieBanner"
          action={setCookieBannerPopUpRulesPath}
          method="POST"
        >
          <input type="hidden" name={pathnameFormName} value={pathname} />
          <Close
            type="submit"
            onClick={handleClick}
            data-name={COOKIE_BANNER_CLOSE_BUTTON_DATA_NAME}
          >
            <CloseSvg stroke="#46aae9" width="18" height="18" />
            <StyledVisuallyHiddenText>
              <FormattedMessage id="app.link.close" defaultMessage="Закрыть" />
            </StyledVisuallyHiddenText>
          </Close>
          <FormattedMessage
            id="cookie.notice"
            defaultMessage="Наш сайт использует файлы cookie. Продолжая использовать сайт, вы даете на это согласие в соответствии с <link>Политикой в отношении обработки персональных данных</link>."
            values={{
              link: (chunks) => (
                <RouterLink
                  to={confidentialityLink}
                  data-name="cookie-message-url-action"
                >
                  {chunks}
                </RouterLink>
              ),
            }}
          />
        </form>
      </WrapperInner>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  border-top: solid 1px rgba(0, 0, 0, 0.1);
  font-family: ${fontFamily};
  font-size: 12px;
  color: #9d9d9d;
  z-index: 7;
`
const WrapperInner = styled.div`
  position: relative;
  max-width: 900px;
  margin: 0 auto;
  padding: 10px 20px;

  ${media.phone`
    padding: 10px 48px 10px 20px;
  `};
`

const Close = styled.button`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  background: none;
  border: 0;

  ${media.phone`
    transform: none;
    right: 13px;
    top: 10px;
  `};
`
