import { AsyncThunkAction } from 'actions/types'
import { pushShortCutNotificationAction } from 'actions/uninotice/noticeShortCutAction'
import {
  convertDiamondsToCoinApi,
  fetchDiamondsSettingsApi,
  fetchPersonalDiamondsAndSettingsApi,
} from 'api/cashOutStreamApi'
import { ApiResult } from 'api/fetchApi'
import { NotificationType } from 'components/presentational/Notifications/NotificationType'
import { goBack, push } from 'functions/router'

export const FETCH_CURRENT_DIAMONDS_AND_SETTINGS =
  'FETCH_CURRENT_DIAMONDS_AND_SETTINGS'

export const CONVERT_DIAMONDS_TO_COIN = 'CONVERT_DIAMONDS_TO_COIN'
export const UPDATE_ACTIVE_TARIFF = 'UPDATE_ACTIVE_TARIFF'
export const FETCH_CURRENT_SETTINGS = 'FETCH_CURRENT_SETTINGS'

export const fetchPersonalDiamondsAndSettingsAction = () => {
  return {
    type: FETCH_CURRENT_DIAMONDS_AND_SETTINGS,
    promise: () => fetchPersonalDiamondsAndSettingsApi(),
  }
}

export function fetchDiamondsSettingsAction() {
  return {
    type: FETCH_CURRENT_SETTINGS,
    promise: () => fetchDiamondsSettingsApi(),
  }
}

export const convertDiamondsToCoinAction = (
  coins: number,
  successPath: string
): AsyncThunkAction => async (dispatch, getState) => {
  const { touch } = getState().systemReducer
  const { result: json } = ((await dispatch({
    type: CONVERT_DIAMONDS_TO_COIN,
    promise: () => convertDiamondsToCoinApi(coins),
  })) as unknown) as { result: ApiResult }

  if (json.ok) {
    if (touch) {
      dispatch(goBack())
    } else {
      dispatch(push(successPath))
    }
    dispatch(pushShortCutNotificationAction(NotificationType.diamonds))
  }
}

export const updateActiveTariffAction = (indexTariff) => {
  return { type: UPDATE_ACTIVE_TARIFF, indexTariff }
}
