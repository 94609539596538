import { AsyncAction, AsyncThunkAction } from 'actions/actions.types'
import { AppDispatchNext, AppGetState } from 'actions/actions.typesNext'
import { StepRegisterWithEmailAction } from 'actions/form/stepRegisterWithEmailAction'
import { fetchMiniIfNeededAction } from 'actions/user/fetchMiniIfNeededAction'
import { saveStepRegistrationDataApi } from 'api/authorization/saveStepRegistrationDataApi'
import { apiResultWithoutErrors } from 'api/function/apiResultWithoutErrors'
import { definitions } from 'api/generated/search'
import { fetchStepRegistrationResultApi } from 'api/internal/fetchStepRegistrationResultApi'
import { fetchSearchSettingsListApi } from 'api/search/fetchSearchSettingsListApi'
import { Constitution, DatingGoals, Gender } from 'common-constants/api6.types'
import {
  findFirstNotFilledStepUrl,
  findNextStepUrl,
} from 'components/page/StepRegistration/StepRegistration.functions'
import { push } from 'functions/router'
import { UpdateFieldAction } from 'reducers/defaultUpdateFieldReducer'
import {
  NumberRange,
  StepRegistrationFieldList,
} from 'reducers/registration/stepRegistrationReducer'

export const UPDATE_REGISTRATION_FIELD = 'UPDATE_REGISTRATION_FIELD'

interface UpdateRegistrationField extends UpdateFieldAction {
  type: typeof UPDATE_REGISTRATION_FIELD
}

export const updateRegistrationFieldAction = (
  name: StepRegistrationFieldList,
  value: string
) => ({
  type: UPDATE_REGISTRATION_FIELD,
  name,
  value,
})

export const UPDATE_REGISTRATION_GENDER = 'UPDATE_REGISTRATION_GENDER'

export interface UpdateRegistrationGenderAction {
  type: typeof UPDATE_REGISTRATION_GENDER
  value: Gender
}

export const updateRegistrationGenderAction = (value: Gender) => ({
  type: UPDATE_REGISTRATION_GENDER,
  value,
})

export const SELECT_REGISTRATION_TARGET_GENDER =
  'SELECT_REGISTRATION_TARGET_GENDER'

export interface SelectRegistrationTargetGenderAction {
  type: typeof SELECT_REGISTRATION_TARGET_GENDER
  value: Gender
}

export const selectRegistrationTargetGenderAction = (value: Gender) => ({
  type: SELECT_REGISTRATION_TARGET_GENDER,
  value,
})

export const SELECT_REGISTRATION_AGE = 'SELECT_REGISTRATION_AGE'

interface SelectRegistrationAgeAction {
  type: typeof SELECT_REGISTRATION_AGE
  value: NumberRange
}

export const selectRegistrationAgeAction = (value: NumberRange) => ({
  type: SELECT_REGISTRATION_AGE,
  value,
})

export const SET_PERSON_HEIGHT = 'SET_PERSON_HEIGHT'

interface SetPersonHeightAction {
  type: typeof SET_PERSON_HEIGHT
  value: NumberRange
  enable: boolean
}

export const setPersonHeightAction = (value: NumberRange) => ({
  type: SET_PERSON_HEIGHT,
  value,
})

export const SET_PERSON_WEIGHT = 'SET_PERSON_WEIGHT'

interface TogglePersonWeightAction {
  type: typeof SET_PERSON_WEIGHT
  value: NumberRange
  enable: boolean
}

export const setPersonWeightAction = (value: NumberRange) => ({
  type: SET_PERSON_WEIGHT,
  value,
})

export const SET_DATING_GOAL = 'SET_DATING_GOAL'

interface SetDatingGoalAction {
  type: typeof SET_DATING_GOAL
  value: DatingGoals
  enable: boolean
}

export const setDatingGoalAction = (value: DatingGoals) => ({
  type: SET_DATING_GOAL,
  value,
})

export const TOGGLE_CONSTITUTION = 'TOGGLE_CONSTITUTION'

interface ToggleConstitutionAction {
  type: typeof TOGGLE_CONSTITUTION
  value: Constitution
  enable: boolean
}

export const toggleConstitutionAction = (
  value: Constitution,
  enable: boolean
) => ({
  type: TOGGLE_CONSTITUTION,
  value,
  enable,
})

export const FETCH_REGISTRATION_SEARCH_SETTINGS = 'FETCH_REGISTRATION_SEARCH_SETTINGS' as const

interface FetchSearchSettingsAction
  extends AsyncAction<definitions['SearchFormResultWithRequest']> {
  type: typeof FETCH_REGISTRATION_SEARCH_SETTINGS
}

export const fetchRegistrationSearchSettingsAction = (): AsyncThunkAction => (
  dispatch
) => {
  dispatch<FetchSearchSettingsAction>({
    type: FETCH_REGISTRATION_SEARCH_SETTINGS,
    promise: () => fetchSearchSettingsListApi(),
  })
}

export const FETCH_REGISTRATION_SEARCH_RESULT = 'FETCH_REGISTRATION_SEARCH_RESULT' as const

interface FetchSearchResultAction extends AsyncAction<number> {
  type: typeof FETCH_REGISTRATION_SEARCH_RESULT
}

export const fetchRegistrationSearchResultAction = (): AsyncThunkAction => (
  dispatch,
  getState
) => {
  const {
    stepRegistration: {
      gender,
      targetGender,
      datingGoal,
      form: { year },
      searchTotal,
    },
  } = getState()

  if (searchTotal) {
    return
  }

  dispatch<FetchSearchResultAction>({
    type: FETCH_REGISTRATION_SEARCH_RESULT,
    promise: () =>
      fetchStepRegistrationResultApi(
        gender,
        targetGender,
        Number(year),
        datingGoal!
      ),
  })
}

export const nextStepAction = (currentPath: string) => (
  dispatch: AppDispatchNext
) => {
  const nextPath = findNextStepUrl(currentPath)
  return dispatch(pushStepAction(nextPath))
}

export const nextStepRegistrationFieldAction = (): AsyncThunkAction => (
  dispatch,
  getState
) => {
  const nextPath = findFirstNotFilledStepUrl(getState().stepRegistration)
  return dispatch(pushStepAction(nextPath))
}

const pushStepAction = (stepPath: string) => (dispatch: AppDispatchNext) => {
  return dispatch(push(stepPath))
}

export const SAVE_STEP_REGISTRATION_DATA = 'SAVE_STEP_REGISTRATION_DATA'

interface SaveStepRegistrationDataAction extends AsyncAction {
  type: typeof SAVE_STEP_REGISTRATION_DATA
}

export const saveStepRegistrationDataAction = () => async (
  dispatch: AppDispatchNext,
  getState: AppGetState
) => {
  const {
    stepRegistration: { targetGender, age, datingGoal },
  } = getState()
  const { result: json } = await dispatch(fetchMiniIfNeededAction())
  // С помощью получения mini узнаем: каптчи нет, GDPR принят, real-статус получен
  if (apiResultWithoutErrors(json)) {
    return dispatch({
      type: SAVE_STEP_REGISTRATION_DATA,
      promise: () => saveStepRegistrationDataApi(targetGender, age, datingGoal),
    })
  }
  // prettier-ignore
  console.error('Can\'t save because of profile status')
  return Promise.reject()
}

export const CLEAR_STEP_REGISTRATION = 'CLEAR_STEP_REGISTRATION'

interface ClearStepRegistrationAction {
  type: typeof CLEAR_STEP_REGISTRATION
}

export const clearStepRegistrationAction = () => ({
  type: CLEAR_STEP_REGISTRATION,
})

export type RegistrationActionTypes =
  | UpdateRegistrationField
  | UpdateRegistrationGenderAction
  | FetchSearchSettingsAction
  | SelectRegistrationTargetGenderAction
  | SelectRegistrationAgeAction
  | SetPersonHeightAction
  | TogglePersonWeightAction
  | ToggleConstitutionAction
  | SetDatingGoalAction
  | FetchSearchResultAction
  | StepRegisterWithEmailAction
  | SaveStepRegistrationDataAction
  | ClearStepRegistrationAction
