import { AsyncAction } from 'actions/actions.types'
import { definitions } from 'api/generated/search'
import { NodeHeaders } from 'api/NodeHeaders'
import { fetchSearchWithParamsApi } from 'api/search/fetchSearchWithParamsApi'
import { DatingGoals } from 'common-constants/api6.types'
import {
  findGenderCode,
  findPreferGenderCode,
} from 'components/page/Landing/Landing.functions'
import { defaultSearchForm } from 'components/page/MainSearch/defaultSearchForm'

export const SERIOUS_SEARCH = 'SERIOUS_SEARCH' as const

export interface SeriousSearchAction
  extends AsyncAction<
    Awaited<ReturnType<typeof fetchSearchWithParamsApi>>['result']
  > {
  type: typeof SERIOUS_SEARCH
}

export type SearchGender = definitions['SearchRequest']['lookFor']

/**
 * @deprecated Удалить после выхода лендига серьезных отношений.
 */
export const fetchSeriousSearchAction = (
  gender: SearchGender,
  headers?: NodeHeaders
) => ({
  type: SERIOUS_SEARCH,
  promise: () =>
    fetchSearchWithParamsApi(
      {
        request: {
          /** Куча дефолтов нужна бекенд, поэтому defaultSearchForm */
          ...((defaultSearchForm as unknown) as definitions['SearchRequest']),
          myGender: 'N',
          country: 3159, // Россия
          withPhoto: true,
          limit: 16, // https://redmine.mamba.ru/issues/115872#note-28
          lookFor: findGenderCode(gender) as SearchGender,
          lookForPrefGender: findPreferGenderCode(gender),
          /** задача на бек https://youtrack.mamba.ru/issue/M-10124 */
          target: ([
            DatingGoals.SeriousRelationship,
          ] as unknown) as definitions['SearchRequest']['target'],
        } as definitions['SearchRequest'],
      },
      headers
    ),
})
