import { AsyncAction } from 'actions/actions.types'
import { LoginAction } from 'actions/authorization/loginAction'
import { LogoutAction } from 'actions/authorization/logoutAction'
import { findCountryCode } from 'actions/profile/function/findCountryCode'
import { AsyncThunkAction } from 'actions/types'
import {
  isAuthorizationMailruApi,
  registrationDataApi,
  verifySmsCodeApi,
} from 'api/authorizationApi'
import { Api6NodeHeaders } from 'api/createApiNodeHeaders'
import { FormBuilder } from 'api/FormBuilder'
import { concatPhone } from 'components/page/RestorePassword/function/concatPhone'
import {
  phoneResetSuccessPath,
  restorePasswordPath,
} from 'components/page/RestorePassword/RestorePasswordLoadable'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { push } from 'functions/router'
import { AuthorizationForm } from 'reducers/authorization/AuthorizationForm'
import { UpdateFieldAction } from 'reducers/defaultUpdateFieldReducer'

import { AppDispatchNext, AppGetState } from './actions.typesNext'
import { RegistrationAction } from './authorization/registrationAction'
import { updateLastAuthMethodAction } from './authorization/updateLastAuthMethodAction'
import { updateLastClickedAuthVendor } from './authorization/updateLastClickedAuthVendor'

export const RESET_PASSWORD_ATTEMPT = 'RESET_PASSWORD_ATTEMPT'

export const UPDATE_AUTH_FIELD_ACTION = 'UPDATE_AUTH_FIELD_ACTION'

interface UpdateAuthFieldAction extends UpdateFieldAction {
  type: typeof UPDATE_AUTH_FIELD_ACTION
}

export const updateAuthFieldAction = (
  name: string,
  value:
    | Record<string, number | string | boolean | null>
    | string
    | boolean
    | AuthorizationForm['phone']
) => ({
  type: UPDATE_AUTH_FIELD_ACTION,
  name,
  value,
})

export const CONFIRM_PHONE_CODE = 'CONFIRM_PHONE_CODE' as const

interface ConfirmSmsCodeAction extends AsyncAction {
  type: typeof CONFIRM_PHONE_CODE
}

export const confirmSmsCodeAction = (
  baseUrl: string = ''
): AsyncThunkAction => (dispatch, getState) => {
  const {
    authorizationReducer: { form },
    countriesInfo: { countriesList },
  } = getState()

  const {
    phone: { phoneNumber, countryCode },
    smsCode,
  } = form

  const foundCountry = findCountryCode(countriesList, countryCode)

  if (smsCode && foundCountry && phoneNumber) {
    const { prefix } = foundCountry
    dispatch({
      type: CONFIRM_PHONE_CODE,
      // TODO проверить, отработает ли нормально капча
      promise: async () => {
        const json = await verifySmsCodeApi(
          concatPhone(prefix, phoneNumber),
          smsCode
        )
        dispatch(smsTimerFinishedAction(false))

        if (json.ok) {
          dispatch(
            push(
              mergeAllUrls(baseUrl, restorePasswordPath, phoneResetSuccessPath)
            )
          )
        }

        return json
      },
    })
  } else {
    console.warn('Sms code not found, action ignored')
  }
}

export const confirmSmsCodeNextAction = () => (
  dispatch: AppDispatchNext,
  getState: AppGetState
) => {
  const {
    systemReducer: { baseUrl },
    authorizationReducer: {
      form: {
        phone: { phoneNumberFull },
        smsCode,
      },
    },
  } = getState()

  if (!phoneNumberFull || !smsCode) {
    console.warn('phoneNumber or smsCode not found, action ignored')
    return
  }

  return dispatch({
    type: CONFIRM_PHONE_CODE,
    promise: async () => {
      const result = await verifySmsCodeApi(
        phoneNumberFull.replace('+', ''),
        smsCode
      )
      dispatch(smsTimerFinishedAction(false))

      if (result.ok) {
        dispatch(
          push(
            mergeAllUrls(baseUrl, restorePasswordPath, phoneResetSuccessPath)
          )
        )
      }

      return result
    },
  })
}

interface ResetPasswordAttemptAction {
  type: typeof RESET_PASSWORD_ATTEMPT
}

export const resetPasswordAttemptAction = () => ({
  type: RESET_PASSWORD_ATTEMPT,
})

export const SMS_TIMER_FINISHED = 'SMS_TIMER_FINISHED'

interface SmsTimerFinishedAction {
  type: typeof SMS_TIMER_FINISHED
  finished: boolean
}

export const smsTimerFinishedAction = (finished: boolean) => {
  return {
    type: SMS_TIMER_FINISHED,
    finished,
  }
}

export const REGISTRATION_DATA = 'REGISTRATION_DATA'

export interface RegistrationDataAction
  extends AsyncAction<{ formBuilder: FormBuilder }> {
  type: typeof REGISTRATION_DATA
}

export const registrationDataAction = (): AsyncThunkAction => (
  dispatch,
  getState
) => {
  dispatch({
    type: REGISTRATION_DATA,
    promise: () => registrationDataApi(getState().systemReducer.locale),
  })
}

export const UPDATE_AUTH = 'UPDATE_AUTH'

interface UpdateAuthorizedAction {
  type: typeof UPDATE_AUTH
  authorized: boolean
}

export const updateAuthorizedAction = (authorized: boolean) => ({
  type: UPDATE_AUTH,
  authorized,
})

export const FETCH_IS_AUTHORIZATION_MAIL_RU = 'FETCH_IS_AUTHORIZATION_MAIL_RU' as const

interface FetchIsAuthorizationMailruAction
  extends AsyncAction<{ result: string }> {
  type: typeof FETCH_IS_AUTHORIZATION_MAIL_RU
}

/**
 * Запрос нужен, чтобы понять, какое поведение должно быть у пользователя в зависимости от того,
 * как он был зарегистрирован: через mail.ru или напрямую через love.mail.ru(доступно после редизайна).
 * А именно, может ли пользователь менять пароль напрямую на love.mail.ru.
 */
export const fetchIsAuthorizationMailruAction = (
  headers?: Api6NodeHeaders
): FetchIsAuthorizationMailruAction => ({
  type: FETCH_IS_AUTHORIZATION_MAIL_RU,
  promise: () => isAuthorizationMailruApi(headers),
})

export type AuthorizationTypes =
  | LoginAction
  | RegistrationAction
  | UpdateAuthFieldAction
  | LogoutAction
  | ConfirmSmsCodeAction
  | ResetPasswordAttemptAction
  | SmsTimerFinishedAction
  | RegistrationDataAction
  | UpdateAuthorizedAction
  | FetchIsAuthorizationMailruAction
  | ReturnType<typeof updateLastAuthMethodAction>
  | ReturnType<typeof updateLastClickedAuthVendor>
