import React, { ComponentPropsWithoutRef, FC, ForwardedRef } from 'react'

import { cx, css } from '@linaria/core'
import { styled } from '@linaria/react'

import { WithChildren } from 'common/types'

export const ListItem: FC<
  {
    _ref?: ForwardedRef<HTMLLIElement>
    size: Size
    active?: boolean
  } & WithChildren &
    ComponentPropsWithoutRef<typeof ListItemInner>
> = ({ _ref, size, onClick, active, children, ...rest }) => {
  return (
    <ListItemInner
      ref={_ref}
      className={cx(
        sizeStylesMap[size],
        active ? activeCss : defaultCss,
        onClick && onClickCss
      )}
      onClick={onClick}
      {...rest}
    >
      <Underlayer />
      {children}
    </ListItemInner>
  )
}

const onClickCss = css`
  cursor: pointer;
`
const Underlayer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--spacing-32px, 32px);
  pointer-events: none;
`
const activeCss = css`
  ${Underlayer} {
    background-color: var(
      --warm-opacity-press-list-item-bg,
      rgba(127, 116, 114, 0.24)
    );
  }
`
const defaultCss = css`
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      ${Underlayer} {
        background-color: var(
          --warm-opacity-hover-list-item-bg,
          rgba(127, 116, 114, 0.08)
        );
      }
    }
    &:active {
      ${Underlayer} {
        background-color: var(
          --warm-opacity-press-list-item-bg,
          rgba(127, 116, 114, 0.24)
        );
      }
    }
  }
`
const ListItemInner = styled.li`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: var(--spacing-32px, 32px);
`

type Size = 'S' | 'M' | 'L'

const sizeStylesMap: Record<Size, string> = {
  S: css`
    padding: var(--spacing-12px, 12px) var(--spacing-16px, 16px);
  `,
  M: css`
    padding: var(--spacing-16px, 16px);
  `,
  L: css`
    padding: var(--spacing-16px, 16px);
  `,
}
