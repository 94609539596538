import { LOCALES_REGEXP } from 'common-constants/locale'

export const nodeJsPort = 3000
export const phpProxyPort = 3010
export const nodeJsHeader = 'x-node'
export const proxyHeader = 'x-wap'
export const manifestPath = '/manifest.json'
export const yandexManifestPath = '/yandex-manifest.json'

export const localeRegExp = new RegExp(`^/(${LOCALES_REGEXP})\\W.*?`, 'i')
export const localeOnlyRegExp = new RegExp(`^/(${LOCALES_REGEXP})/?$`, 'i')
