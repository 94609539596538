import cookie from 'js-cookie'

import { CATCH_NETWORK_ERROR, features, details } from 'api/api.constants'
import { setSentryTags } from 'api/api.functions'
import { Api6Error, AppNetworkError } from 'api/api.types'
import { Api6Errors } from 'api/Api6Errors'
import { ApiResult } from 'api/fetchApi'
import { FormBuilder } from 'api/FormBuilder'
import { DeprecatedApi6Error } from 'api/types'
import { consoleTime, consoleTimeEnd } from 'common/consoleLog'
import { sendApiBtp } from 'functions/index'
import { resolveDeviceId } from 'functions/localStorage'

import { MAMBA_FEATURES } from '../constants'

/** Для устаревшего много лет назад АПИ пойдет */
const API_5_WEB = process.env.API_5_HOST
export const API_6 = process.env.API_6_HOST
export const API6_ERROR = 'API6_ERROR'
export const API5_ERROR = 'API5_ERROR'

export const API_6_BTP = 'qapibara'
export const API_5_BTP = 'api5'

export type BtpApiVersion = typeof API_6_BTP | typeof API_5_BTP

export const GET_BTP = 'GET'
export const POST_BTP = 'POST'

export type BtpApiMethod = typeof GET_BTP | typeof POST_BTP

export const defaultNodeOptions = {
  redirect: 'manual', // https://github.com/bitinn/node-fetch#fetch-options
}

// Ниже коды ошибок только из API5. Коды ошибок из API6 приходят в текстовом значение.
export const realStatusRequiredCode = 1 // Неправильно введены авторизационные данные...далее см. API5 - коды ошибок
export const profilePersonalBannedCode = 2 // Персональные данные (например: имя) отклонены модератором...далее см. API5 - коды ошибок
export const chatWithMySelf = 8 // Попытка коммуникации с собой
export const profileRemovedCode = 30 // Пользователь удален
export const profileBannedCode = 32 // Вы заблокированы Модератор посчитал вас кем-то кому не место на сайте
export const ipBlockedCode = 27 // IP адрес заблокирован
export const trackBlockedCode = 131 // Компьютер заблокирован
export const gdprCode = 199 // Аккаунт пользователя был деактивирован в связи с отсутствием согласия (GDPR).
export const additionalRegistrationNeededCode = 47 // Редирект на форму регистрации В случае авторизации с дорегистрацией отправляем клиента на дорегистрацию
export const partnerRegistrationCode = 120 // Пользователь пытается зарегистрироваться на парнёрской версии знакомств без наличия авторизации на основном сайте партнёра с кросс-авторизацией. Как правило, это означает, что пользователя требуется перенаправить на страницу регистрации на сайте партнёра.

/**
 * @deprecated fetchApi.ts
 * @param url
 * @param options
 * @param withHeaders
 */
export async function fetchApi(
  url: string,
  options: RequestInit = {},
  withHeaders = false
) {
  const timerName = `fetch API6 ${url} ${Math.round(1000 * Math.random())}`
  consoleTime(timerName)
  // TODO пока закомментил для истории, после релиза удалю
  // const transaction = startTransaction({
  //   op: 'fetch v.1',
  //   name: `URL ${cleanUrlForSentry(url)}`,
  // })

  try {
    options.credentials = 'same-origin'
    options.headers = {
      ...options.headers,
      'Content-Type': 'application/json; charset=utf-8',
    } as HeadersInit

    if (process.env.browser && options.method && options.method !== 'GET') {
      options.headers['Csrf-Token'] = cookie.get('s_post')
    }

    if (process.env.browser && window.API_6_CLIENT) {
      options.headers['Mamba-Client'] = JSON.stringify(window.API_6_CLIENT)
    }

    if (process.env.browser) {
      options.headers['X-Requested-With'] = 'XMLHttpRequest'
    }

    options.headers[MAMBA_FEATURES] = JSON.stringify({
      features,
      details,
    })

    const currentUuid = resolveDeviceId()

    if (
      process.env.browser === true &&
      currentUuid !== null &&
      currentUuid !== 'null'
    ) {
      options.headers['Mamba-Device-Id'] = currentUuid
    }

    if (process.env.browser === false) {
      options.headers['X-Node'] = 'true'
    }

    const promise = fetch(API_6 + url, options)
    sendApiBtp(promise, API_6_BTP, url, options)
    const response = await promise

    setSentryTags(response)

    if (response.status === 204 || response.status === 500) {
      consoleTimeEnd(timerName)
      // transaction.finish()

      if (process.env.browser === false) {
        return {
          ok: response.ok,
          status: response.status,
          // @ts-expect-error Все равно deprecated
          headers: response.headers.raw(),
        }
      }
      return {
        ok: response.ok,
        status: response.status,
        // headers: response.headers,
        /** проставляем поля, для явного отделения старого метода fetchApi */
        legacy: true,
      }
    }

    const json = await response.json()
    consoleTimeEnd(timerName)
    // transaction.finish()

    if (response.ok) {
      if (withHeaders) {
        // @ts-expect-error Все равно deprecated
        return { json, headers: response.headers.raw() }
      }
      return json
    } else {
      return ({
        code: API6_ERROR,
        internalError: json,
        errorStatus: response.status,
        /** Метод запрекейчен и будет удален, но для типов оставим так */
      } as unknown) as ApiResult
    }
  } catch (error) {
    consoleTimeEnd(timerName)
    // transaction.finish()

    console.error(`API6 ${url}`, error)
    throw new AppNetworkError(error.message)
  }
}

export const fetchApi5 = async (url: string, options: RequestInit = {}) => {
  const path = process.env.browser ? window.API_5_PATH || API_5_WEB : API_5_WEB

  const timerName = `fetch API5 ${path}${url}`
  consoleTime(timerName)

  options.headers = options.headers || {}

  if (process.env.browser === false) {
    options.headers['X-Node'] = 'true'
  }

  if (process.env.browser) {
    options.headers['X-Requested-With'] = 'XMLHttpRequest'
  }

  options.headers[MAMBA_FEATURES] = JSON.stringify({
    features,
    details,
  })

  try {
    options.credentials = 'same-origin'

    const promise = fetch(path + url, options)

    sendApiBtp(promise, API_5_BTP, url, options)
    const response = await promise

    setSentryTags(response)

    if (response.status === 204 || response.status === 500) {
      return { ok: response.ok, status: response.status }
    }

    const json = await response.json()
    consoleTimeEnd(timerName)
    if (!response.ok || json.errorCode) {
      return {
        code: API5_ERROR,
        internalError: json,
      }
    } else {
      return json
    }
  } catch (error) {
    consoleTimeEnd(timerName)
    console.error(`API5 ${url}`, error)
    throw new AppNetworkError(error.message)
  }
}
/**
 * @deprecated src/common/api/function/apiResultHasErrors.ts
 */
export const apiResultHasErrors = (result) =>
  result &&
  [API6_ERROR, API5_ERROR, CATCH_NETWORK_ERROR].indexOf(result.code) > -1

export const apiResultHasErrorMessages = (result) =>
  result.errors && Object.keys(result.errors).length > 0

/**
 * @deprecated src/common/api/function/apiResultWithoutErrors.ts
 */
export const apiResultWithoutErrors = (result) => !apiResultHasErrors(result)

export const isApi5CaptchaError = (result) => isApi5ErrorCode(result, 26)

export const isApi5TrackError = (result) =>
  isApi5ErrorCode(result, trackBlockedCode)

export const isApi5IpError = (result) => isApi5ErrorCode(result, ipBlockedCode)

export const isApi6IpError = (result) => isApi6ErrorCode(result, 'blocked_ip')

export const isApi6PersonalBlockedError = (result) =>
  isApi6ErrorCode(result, Api6Errors.profileRejected)

export const isApi6PersonalBlockedErrorCode = (result) =>
  /** https://youtrack.mamba.ru/issue/M-9980 @TODO Почему ответ сервера нестандартный? */
  result?.code === Api6Errors.profileRejected

export const isApi5PersonalBlockedError = (result) =>
  isApi5ErrorCode(result, profilePersonalBannedCode)

export const isApi5UserHideError = (result) => isApi5ErrorCode(result, 3)

export const isApi5BlockedOrNotExistProfileError = (result) =>
  isApi5ErrorCode(result, 4)

export const isApi5GdprError = (result) => isApi5ErrorCode(result, gdprCode)

export const isApi6GdprError = (result) =>
  isApi6ErrorCode(result, 'user_account_was_deactivated')

export const isApi5CurrentProfileInIgnoreListError = (result) =>
  isApi5ErrorCode(result, 181)

export const isApi5ChatWithMySelfError = (result) =>
  isApi5ErrorCode(result, chatWithMySelf)

/**
 * Из документации:
 * "Редирект на страницу авторизации Если в ответе установить сообщение (setMessage), тогда оно будет отображено на странице авторизации"
 * @param result
 * @returns {Boolean}
 */
export const isApi5EmailSuccessError = (result) => isApi5ErrorCode(result, 54)

export const isApi5ErrorCode = (result, code) =>
  result &&
  result.code === API5_ERROR &&
  result.internalError.errorCode === code

const isApi6ErrorCode = (result, code) =>
  result && result.code === API6_ERROR && result.internalError.code === code

export const isUnexpectedServerError = (result) => result.status === 500

export const realStatusNeededCode = (error) => {
  /**
   * Ответ перестал
   * содержать старые поля.
   * Поэтому оставляю и старые проверки (еще есть где старое апи), и добавлю новое.
   * http://deploy.lan/code/uniweb/merge/96959#src/common/store/storeMiddleware.ts
   */
  if (error?.code === 'real_status') {
    return true
  }

  return (
    error?.code === API6_ERROR && error?.internalError?.code === 'real_status'
  )
}

export const errorCodeEquals = (
  error: DeprecatedApi6Error | Api6Error | undefined,
  code: string | number
) => {
  const apiResult = error as Api6Error
  const deprecated = error as DeprecatedApi6Error

  if (apiResult?.code && !deprecated.internalError) {
    return apiResult?.code === code
  }

  return (
    deprecated &&
    deprecated.internalError &&
    (deprecated.internalError.code ||
      deprecated.internalError.errorCode ||
      deprecated.internalError.errorStatus) === code
  )
}

export const errorCodeNotEquals = (error: DeprecatedApi6Error, code: string) =>
  !errorCodeEquals(error, code)

export const formBuilderHasErrors = (formBuilder) => {
  if (formBuilder) {
    const fields = formBuilder.blocks.reduce(
      (acc, { fields }) => [...acc, ...fields],
      []
    )
    return fields.some((field) => field.error)
  }
  return false
}

export const extractFormFieldsErrors = (
  formBuilder: FormBuilder
): Record<string, string> =>
  formBuilder.blocks
    .reduce((acc, { fields }) => [...acc, ...fields], [])
    .reduce((acc, { field, error }) => {
      if (error) {
        return { ...acc, [field]: error }
      }
      return acc
    }, {})

export const extractGenericFormError = (formBuilder) =>
  formBuilder.blocks[0] && formBuilder.blocks[0].error

export const extractFieldsValues = (formBuilder, initialValue = {}) => {
  if (formBuilder) {
    return formBuilder.blocks
      .reduce((acc, { fields }) => [...acc, ...fields], [])
      .reduce((acc, { field, value, enable, displayName, variants }) => {
        return {
          ...acc,
          [field]: { value, displayName, variants, enable },
        }
      }, initialValue)
  }
}

export const formBuilderHasField = (formBuilder, fieldName, index = 0) =>
  Boolean(
    formBuilder.blocks[index].fields.find(
      ({ field, variants }) => field === fieldName && variants.length
    )
  )

export const extractFieldsFlatValues = (values) =>
  Object.keys(values).reduce(
    (acc, field) => ({ ...acc, [field]: values[field].value }),
    {}
  )

export const createLocaleQuery = (locale: string | undefined) => {
  if (locale) {
    return `_loc[locale]=${locale}`
  }
  return ''
}

export const createLocaleQueryApi5 = (locale: string) => {
  if (locale) {
    return `langId=${locale}`
  }
  return ''
}
