import React, { FC } from 'react'

import { FormattedMessage } from 'react-intl'

import { RouterLink } from 'components/presentational/link'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'

import { supportFormPath } from '../SupportForm/SupportForm.paths'

export const SupportButton: FC = () => {
  const { authorized, locale, baseUrl } = useShallowEqualSelector(
    ({
      authorizationReducer: { authorized },
      systemReducer: { locale, baseUrl },
    }) => ({
      authorized,
      locale,
      baseUrl,
    })
  )

  return (
    <RouterLink
      to={mergeAllUrls(authorized ? baseUrl : locale, supportFormPath)}
      data-name="support"
    >
      <FormattedMessage id="app.support" />
    </RouterLink>
  )
}
