import React from 'react'

import loadable from '@loadable/component'

import { AppRoute } from 'components/components.types'
import { AnnounceLoadable } from 'components/page/Announce/AnnounceLoadable'
import { dropSupportOldBrowserInitialComponentData } from 'components/page/DropSupportOldBrowser/dropSupportOldBrowserInitialComponentData'
import { DropSupportOldBrowserLoadable } from 'components/page/DropSupportOldBrowser/DropSupportOldBrowserLoadable'
import { FeedPageLoadable } from 'components/page/Feed/FeedPageLoadable'
import { geoSeoLandingRoutes } from 'components/page/Landing/Landing.constants'
import { ProfileSwitchLoadable } from 'components/page/ProfileSwitch/ProfileSwitchLoadable'
import { RatingSwitch } from 'components/page/RatingWave/RatingSwitch'
import { searchPath } from 'components/page/Search/Search.paths'
import { SearchLoadable } from 'components/page/Search/SearchLoadable'
import { userProfileAlbumRoutes } from 'components/page/UserProfile/UserProfileAlbum/UseerProfileAlbum.routes'
import { vipTele2Path } from 'components/page/VipTele2/VipTele2.paths'
import { VipTele2Loadable } from 'components/page/VipTele2/VipTele2Loadable'
import {
  announceAddPath,
  dropSupportOldBrowserPath,
  feedAllPath,
  feedFilterPath,
  feedPath,
  ratingPath,
  uniNoticeInlinePath,
  userProfilePath,
  userProfileSearchPath,
} from 'components/paths'
import { pwaRoutes } from 'components/routes/pwaRoutes'
import RedirectWithStatus from 'components/system/RedirectWithStatus'
import { UniNoticeInlinePageLoadable } from 'components/uninotice/UniNoticeInlineLoadable'

export const commonRoutes: AppRoute[] = [
  {
    path: vipTele2Path,
    component: VipTele2Loadable,
    exact: false,
  },
  ...pwaRoutes,
  {
    path: feedFilterPath,
    component: FeedPageLoadable,
    authorizedOnly: true,
    exact: false,
  },
  {
    path: feedPath,
    component: () => (
      <RedirectWithStatus
        status={301}
        to={feedAllPath}
        uid="commonRoutes-feedAllPath"
      />
    ),
    authorizedOnly: true,
    exact: false,
  },
  {
    path: searchPath,
    component: SearchLoadable,
    shouldCheck: true,
    exact: false,
  },
  {
    path: ratingPath,
    component: RatingSwitch,
    shouldCheck: true,
    exact: false,
  },
  ...geoSeoLandingRoutes,
  {
    path: userProfileSearchPath,
    component: ProfileSwitchLoadable,
    shouldCheck: true,
    exact: false,
  },
  ...userProfileAlbumRoutes,
  {
    path: userProfilePath,
    component: loadable(() =>
      import('components/page/ProfileSwitch/ProfileSwitch')
    ),
    shouldCheck: true,
    exact: false,
  },
  {
    path: uniNoticeInlinePath,
    component: UniNoticeInlinePageLoadable,
    shouldCheck: true,
    exact: false,
  },
  {
    path: announceAddPath,
    component: AnnounceLoadable,
    fetchInitialComponentData: () => Promise.resolve(),
    shouldCheck: true,
    exact: false,
  },
  {
    path: dropSupportOldBrowserPath,
    component: DropSupportOldBrowserLoadable,
    fetchInitialComponentData: dropSupportOldBrowserInitialComponentData,
    shouldCheck: true,
    exact: true,
  },
]
