import { InMemoryCache } from '@apollo/client/cache'

/**
 * По умолчанию GQL ищет внутри объекта поле ID
 * Если такого нет, то надо задать через keyFields: ['uniqId']
 */
export function createInMemoryCache() {
  return new InMemoryCache({
    typePolicies: {
      /** RootQuery */
      Query: {
        fields: {
          user: {
            /** данный ключ будет использоваться в хранилище GQL в виде user: { userId: number} */
            keyArgs: ['userId'],
          },
        },
      },
      My: {
        keyFields: [],
        fields: {
          vip: {
            merge: true,
          },
          photos: {
            merge: (existing, incoming) => {
              return {
                ...existing,
                ...incoming,
              }
            },
          },
        },
      },
      MyGifts: {
        keyFields: [],
      },
      MyProfile: {
        keyFields: [],
      },
      MyVipInfo: {
        keyFields: [],
      },
      MyTravelAtlasVisitedCountries: {
        keyFields: [],
      },
      MyEncounters: {
        keyFields: [],
      },
      MyInterests: {
        keyFields: [],
        fields: {
          interests: {
            merge: false,
          },
          interestsCount: {
            merge: false,
          },
        },
      },
      PhotoRating: {
        fields: {
          queue: {
            keyArgs: false,
            merge: true,
          },
        },
      },
      RatingVotingPhotosSet: {
        fields: {
          photos: {
            merge: (existing = [], incoming) => {
              return [...existing, ...incoming]
            },
          },
        },
      },
      PublicInterests: {
        fields: {
          interests: {
            keyArgs: [],
          },
        },
      },
      PublicTravelAtlasVisitedCountries: {
        fields: {
          visitedCountries: {
            keyArgs: [],
          },
        },
      },
      PublicTravelAtlasBadges: {
        fields: {
          badges: {
            keyArgs: [],
          },
        },
      },
      PublicInterest: {
        keyFields: ['interestId'],
      },
      PublicProfile: {
        fields: {
          relations: {
            merge: (existing, incoming) => {
              return {
                ...existing,
                ...incoming,
              }
            },
          },
          location: {
            merge: (existing, incoming) => {
              return {
                ...existing,
                ...incoming,
              }
            },
          },
          vip: {
            merge: (existing, incoming) => {
              return {
                ...existing,
                ...incoming,
              }
            },
          },
          verification: {
            merge: (existing, incoming) => {
              return {
                ...existing,
                ...incoming,
              }
            },
          },
          photos: {
            merge: (existing, incoming) => {
              return {
                ...existing,
                ...incoming,
              }
            },
          },
          photo: {
            merge: (existing, incoming) => {
              return {
                ...existing,
                ...incoming,
              }
            },
          },
        },
      },
      MyGiftImage: {
        keyFields: ['name'],
      },
      Gifts: {
        fields: {
          gifts: {
            keyArgs: [],
          },
        },
      },
      Comment: {
        keyFields: ['id'],
      },
      ParentComment: {
        keyFields: ['id'],
      },
      Photo: {
        fields: {
          urls: {
            merge: (existing, incoming) => {
              return {
                ...existing,
                ...incoming,
              }
            },
          },
        },
      },
      HitList: {
        keyFields: [],
        fields: {
          newHitsCounters: {
            merge: (_, incoming) => {
              return incoming
            },
          },
          hits: {
            keyArgs: ['group'],
            merge: (existing, incoming, options) => {
              if ((existing && !options?.variables?.cursor) || !existing) {
                return incoming
              }
              if (existing && options?.variables?.cursor) {
                return {
                  ...existing,
                  pageInfo: incoming.pageInfo,
                  edges: [...existing.edges, ...incoming.edges],
                }
              }
            },
          },
        },
      },
      Ui: {
        keyFields: false,
      },
    },
  })
}
